import './bootstrap';
import '../css/app.css';
import '@scss/styles.scss';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { LaravelReactI18nProvider} from 'laravel-react-i18n';
import { router } from '@inertiajs/react'
import AppLayout from "@/Layouts/AppLayout.jsx";
// import { Provider } from 'react-redux';
// import { store } from '../redux/store.js';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

// Google analytics
if (process.env.NODE_ENV === "production") {
    router.on("navigate", () => {
        gtag("js", new Date());
        gtag("config", "G-V5PEQVGLGH");
    });
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);
        root.render(
            <LaravelReactI18nProvider
                locale={props.initialPage.props.locale}
                fallbackLocale={'en'}
                files={import.meta.glob('/lang/*.json', {eager: true})}
            >
                {/*<Provider>*/}
                <AppLayout>
                    <App {...props} />
                </AppLayout>
                {/*</Provider>*/}
            </LaravelReactI18nProvider>
        );
    },
    progress: {
        color: '#4B5563',
    },
});
